import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import Navigation from '@/components/organisms/Navigation';
import Footer from '@/components/organisms/Footer';
import Cookies from '@/components/molecules/Cookies';

interface Props {
  children: React.ReactNode;
  invertNav?: boolean;
  show?: boolean;
  type?: `usine` | `magasin`;
}

export const query = graphql`
  query Company {
    prismicCompanyInfos {
      ...CompanyInfos
    }
  }
`;

const Layout = ({
  type = `usine`,
  show = true,
  invertNav,
  children,
}: Props): JSX.Element => {
  const data = useStaticQuery(query);

  const CompanyInfos = data?.prismicCompanyInfos?.data || {};

  return (
    <>
      <Navigation invert={invertNav} show={show} type={type} />
      <div>{children}</div>
      <Footer companyInfos={CompanyInfos} type={type} />
      <Cookies />
    </>
  );
};

export default Layout;
