import React, { HTMLAttributes } from 'react';
import styled from '@emotion/styled';

export interface ErrorProps extends HTMLAttributes<HTMLParagraphElement> {
  children: React.ReactNode;
}

const ErrorRoot = styled.p`
  color: ${({ theme }) => theme.color.error.main};
  font-size: 1.4rem;
`;

/**
 <h3>A simple text component with some styling to display a form error</h3>
 **/
const FormError = ({ children, ...rest }: ErrorProps): JSX.Element => {
  return <ErrorRoot {...rest}>{children}</ErrorRoot>;
};

export default FormError;
