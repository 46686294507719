import React from 'react';
import styled from '@emotion/styled';
import { css, Theme } from '@emotion/react';

import setColor from '@/utils/setColor';

import ColorType from '@/types/style/color';

type ButtonVariantType = 'contained' | 'outlined' | 'text' | 'fab';
type ButtonSizeType = 'sm' | 'md' | 'lg';

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children: React.ReactNode;
  /**
   Change the aspect of the button

   contained = Basic button with a background color
   outlined = Button with no background but a border
   text = Button has the aspect of a text
   fab = Round button usually with an icon
   */
  variant?: ButtonVariantType;
  /**
   Change the color of the button

   contained = change background color<br>
   outlined = change border and text color<br>
   text = change text color<br>
   fab = change background color
   */
  color?: ColorType;
  /**
   Change the padding size of the button
   */
  size?: ButtonSizeType;
  /**
   Change the component used when displaying the button
   */
  as?: React.ElementType;
}

const renderButtonStyle = (
  variant: ButtonVariantType,
  color: ColorType,
  theme: Theme,
) => {
  let style = '';

  switch (variant) {
    case 'outlined':
      // Specific color style
      if (color === 'primary') {
        style += `
          color: ${theme.color.primary.main};
          &:hover {
            color: ${theme.color.white.main};
          }
        `;
      }
      if (color === 'secondary') {
        style += `
          color: ${theme.color.primary.main};

          &:hover {
            color: ${theme.color.primary.main};
          }
        `;
      }
      if (color === 'white') {
        style += `
          color: ${theme.color.white.main};
          &:hover {
            color: ${theme.color.textPrimary.main};
          }
        `;
      }
      if (color === 'black') {
        style += `
          color: ${theme.color.black.main};
          &:hover {
            color: ${theme.color.white.main};
          }
        `;
      }

      style += `
        background: transparent;
        border-width: 2px;
        border-style: solid;
        border-color: ${setColor(color)};

        &:hover {
          background-color: ${setColor(color)};
        }
      `;

      return css`
        ${style}
      `;
    case 'text':
      return css`
        background: none;
        padding: 0;
        color: ${setColor(color)};
      `;
    case 'fab':
      if (color === 'primary') {
        style += `
          color: ${theme.color.white.main};
          border-color: ${theme.color.primary.main};

          &:hover {
            color: ${theme.color.primary.main};
            background-color: ${theme.color.white.main};
          }
        `;
      }
      if (color === 'secondary') {
        style += `
          color: ${theme.color.primary.main};
          &:hover {
            color: ${theme.color.primary.main};
            background-color: ${theme.color.secondary.dark};
          }
        `;
      }
      if (color === 'white') {
        style += `
          color: ${theme.color.primary.main};
          border-color: ${theme.color.primary.main};

          &:hover {
            color: ${theme.color.white.main};
            background-color: ${theme.color.primary.main};
          }
        `;
      }
      if (color === 'black') {
        style += `
          color: ${theme.color.white.main};
          border-color: ${theme.color.black.main};
          &:hover {
            color: ${theme.color.black.main};
            background-color: ${theme.color.white.main};
          }
        `;
      }

      style += `
        border-radius: 50%;
        border-width: 2px;
        border-style: solid;
        background-color: ${setColor(color)};
      `;

      return css`
        ${style}
      `;
    // Default is contained variant
    default:
      if (color === 'primary') {
        style += `
          color: ${theme.color.white.main};
          border-color: ${theme.color.primary.main};

          &:hover {
            color: ${theme.color.primary.main};
            background-color: ${theme.color.white.main};
            border-color: ${theme.color.primary.main};
          }
        `;
      }
      if (color === 'secondary') {
        style += `
          color: ${theme.color.primary.main};
          border-color: ${theme.color.secondary.main};

          &:hover {
            color: ${theme.color.primary.main};
            background-color: ${theme.color.secondary.dark};
            border-color: ${theme.color.secondary.dark};
          }
        `;
      }
      if (color === 'white') {
        style += `
          color: ${theme.color.primary.main};
          border-color: ${theme.color.primary.main};

          &:hover {
            color: ${theme.color.white.main};
            background-color: ${theme.color.primary.main};
            border-color: ${theme.color.primary.main};
          }
        `;
      }
      if (color === 'black') {
        style += `
          color: ${theme.color.white.main};
          border-color: ${theme.color.black.main};

          &:hover {
            color: ${theme.color.black.main};
            background-color: ${theme.color.white.main};
          }
        `;
      }

      style += `
        border-width: 2px;
        border-style: solid;
        background-color: ${setColor(color)};
        border-radius: 5px;
      `;

      return css`
        ${style}
      `;
  }
};

const renderSize = (
  size: ButtonSizeType,
  variant: ButtonVariantType,
  theme: Theme,
) => {
  if (variant === 'fab') {
    switch (size) {
      case 'lg':
        return `
        height: ${theme.spacing(7)};
        width: ${theme.spacing(7)};
        padding: 0`;
      case 'sm':
        return `
        height: ${theme.spacing(3)};
        width: ${theme.spacing(3)};
        padding: 0`;
      default:
        return `
        height: ${theme.spacing(5.5)};
        width: ${theme.spacing(5.5)};
        padding: 0`;
    }
  }
  switch (size) {
    case 'lg':
      return `padding-top: ${theme.spacing(1.2)};
      padding-bottom: ${theme.spacing(1.2)};
      padding-left: ${theme.spacing(4)};
      padding-right: ${theme.spacing(4)};
      `;
    case 'sm':
      return `padding-top: ${theme.spacing(0.85)};
      padding-bottom: ${theme.spacing(0.85)};
      padding-left: ${theme.spacing(2)};
      padding-right: ${theme.spacing(2)};
      `;
    default:
      return `padding-top: ${theme.spacing(0.9)};
      padding-bottom: ${theme.spacing(0.9)};
      padding-left: ${theme.spacing(3)};
      padding-right: ${theme.spacing(3)};
      `;
  }
};

const ButtonRoot = styled.button<ButtonProps>`
  cursor: pointer;
  display: inline-block;
  ${({ size = 'md', variant = 'contained', theme }) =>
    renderSize(size, variant, theme)};
  ${({ variant = 'contained', color = 'primary', theme }) =>
    renderButtonStyle(variant, color, theme)};
  transition: all 0.3s ease;
  font-size: 1.7rem;
  font-weight: 800;
`;

/**
 <h3>Display a button for user interaction</h3>
 **/
const Button = ({
  children,
  variant = 'contained',
  color = 'primary',
  as = 'button',
  size = 'md',
  ...props
}: ButtonProps): JSX.Element => {
  return (
    <ButtonRoot variant={variant} color={color} as={as} size={size} {...props}>
      {children}
    </ButtonRoot>
  );
};

export default Button;
