import React, { ForwardedRef, HTMLAttributes } from 'react';
import styled from '@emotion/styled';

import Label from '@/components/atoms/Form/Label';

export type InputType = 'text' | 'email' | 'tel';

export interface InputProps extends HTMLAttributes<HTMLInputElement> {
  name: string;
  placeholder?: string;
  /**
  Set a label associated with the input
   */
  label?: string;
  required?: boolean;
  /**
   The type of the input
   */
  type?: InputType;
}

const InputRoot = styled('input')`
  padding: ${({ theme }) => theme.spacing(1.5)};
  border: none;
  border-radius: 5px;
  background-color: ${({ theme }) => theme.color.secondary.dark};
  width: 100%;

  &::placeholder {
    color: #c5c5c5;
    font-size: 1.4rem;
    font-weight: 600;
  }
`;

const InputLabel = styled(Label)`
  margin-bottom: ${({ theme }) => theme.spacing(1)};
`;

/**
 <h3>Display an input</h3>
 **/
const Input = React.forwardRef(
  (
    { label, type = 'text', name, ...props }: InputProps,
    ref: ForwardedRef<HTMLInputElement>,
  ): JSX.Element => {
    return (
      <>
        {label && <InputLabel htmlFor={name}>{label}</InputLabel>}
        <InputRoot type={type} ref={ref} id={name} name={name} {...props} />
      </>
    );
  },
);

export default Input;
