import React, { useState, useEffect } from 'react';
import CookieConsent, { Cookies } from 'react-cookie-consent';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import * as ReactGA from 'react-ga';

import Typography from '@/components/atoms/Typography';
import Button from '@/components/atoms/Button';
import Checkbox from '@/components/atoms/Checkbox';

import mq from '@/styles/mq';

const Root = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: ${({ theme }) => theme.color.white.main};
  position: fixed;
  bottom: 3rem;
  left: 3rem;
  border-radius: 10px;
  transform: translateY(0%);
  z-index: 999;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
    rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  -webkit-box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
    rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  -moz-box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
    rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  width: 80%;
  padding-top: ${({ theme }) => theme.spacing(1.5)};
  padding-bottom: ${({ theme }) => theme.spacing(1.5)};
  padding-left: ${({ theme }) => theme.spacing(1.5)};
  padding-right: ${({ theme }) => theme.spacing(1.5)};
  label {
    display: flex;
    flex-direction: column;
  }
  ${mq('xxs')} {
    flex-direction: column;
    /* width: 600px; */
    padding-top: ${({ theme }) => theme.spacing(1)};
    padding-bottom: ${({ theme }) => theme.spacing(1)};
    padding-left: ${({ theme }) => theme.spacing(1.5)};
    padding-right: ${({ theme }) => theme.spacing(1.5)};
  }
  ${mq('md')} {
    label {
      flex-direction: row;
    }
    flex-direction: row;
    /* width: 800px; */
    padding-top: ${({ theme }) => theme.spacing(1.5)};
    padding-bottom: ${({ theme }) => theme.spacing(1.5)};
    padding-left: ${({ theme }) => theme.spacing(2)};
    padding-right: ${({ theme }) => theme.spacing(2)};
  }
  ${mq('lg')} {
    /* width: 1000px; */
    padding-top: ${({ theme }) => theme.spacing(2)};
    padding-bottom: ${({ theme }) => theme.spacing(2)};
    padding-left: ${({ theme }) => theme.spacing(4)};
    padding-right: ${({ theme }) => theme.spacing(4)};
  }
`;

const CookiesCheckbox = styled(Checkbox)`
  margin-top: ${({ theme }) => theme.spacing(1)};
  margin-bottom: ${({ theme }) => theme.spacing(2)};
  ${mq('md')} {
    margin-bottom: ${({ theme }) => theme.spacing(0)};
    margin-top: ${({ theme }) => theme.spacing(0)};
  }
`;
const CheckBoxText = styled(Typography)`
  margin-top: ${({ theme }) => theme.spacing(1)};
  ${mq('md')} {
    margin-top: ${({ theme }) => theme.spacing(0)};
  }
`;

const CookiesBanner = (): JSX.Element => {
  const [open, setOpen] = useState<boolean>(false);
  const [checked, setChecked] = useState<boolean>(true);
  const ConfirmCookie = () => {
    if (process.env.GATSBY_GOOGLE_ANALYTICS_ID) {
      if (process.env.NODE_ENV === 'production') {
        if (checked) {
          ReactGA.initialize(process.env.GATSBY_GOOGLE_ANALYTICS_ID, {
            gaOptions: {
              sampleRate: 100,
              siteSpeedSampleRate: 100,
            },
          });
          localStorage.setItem('cookieConsent', 'validated');
          Cookies.set('gatsby-gdpr-google-analytics', true);
          setOpen(false);
        } else {
          localStorage.setItem('cookieConsent', 'validated');
          Cookies.set('gatsby-gdpr-google-analytics', false);
          setOpen(false);
        }
      }
    }
  };

  useEffect(() => {
    if (localStorage.getItem('cookieConsent') === 'validated') {
      setOpen(false);
    } else {
      setOpen(true);
    }
  }, []);

  return (
    <Root
      css={
        !open &&
        css`
          transform: translateY(150%);
          transition: all 0.3s cubic-bezier(0.6, -0.28, 0.735, 0.045);
        `
      }
    >
      <CookiesCheckbox
        id="cookies-consent"
        //onChange={handleCheck}
        checked={checked}
        onClick={() => setChecked(!checked)}
        icon
      >
        <CheckBoxText variant="textMd">
          J'accepte que mes données personnelles soient traitées à des fins
          marketing
        </CheckBoxText>
      </CookiesCheckbox>
      <Button variant="contained" size="sm" onClick={ConfirmCookie}>
        Enregistrer
      </Button>
    </Root>
  );
};

export default CookiesBanner;
