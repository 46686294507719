import React, { HTMLAttributes } from 'react';
import styled from '@emotion/styled';
import { css, Theme } from '@emotion/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faTimesCircle } from '@fortawesome/pro-solid-svg-icons';
import setColor from '@/utils/setColor';
import ColorType from '@/types/style/color';

interface Props extends HTMLAttributes<HTMLInputElement> {
  children?: React.ReactNode;
  checked?: boolean;
  icon?: boolean;
}

interface CheckedIconProps {
  color?: ColorType;
}

const CheckboxButton = styled('span')`
  width: 15px;
  height: 15px;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.color.textSecondary.light};
`;
const CheckboxInput = styled('input')`
  display: none;

  &:checked + label ${CheckboxButton} {
    background-color: ${({ theme }) => theme.color.primary.main};
  }
`;
const CheckboxLabel = styled('label')`
  cursor: pointer;
  display: flex;
  align-items: center;
`;
const CheckedIcon = styled(FontAwesomeIcon)<CheckedIconProps>`
  color: ${({ color = 'primary' }) => setColor(color)};
`;

const Margin = (theme: Theme) => css`
  margin-right: ${theme.spacing(2)};
`;

const Checkbox = ({
  id,
  className,
  defaultChecked,
  children,
  checked,
  icon = false,
  ...rest
}: Props): JSX.Element => {
  return (
    <>
      <CheckboxInput
        defaultChecked={defaultChecked}
        checked={checked}
        type="checkbox"
        id={id}
        {...rest}
      />
      <CheckboxLabel htmlFor={id} className={className}>
        {!icon && <CheckboxButton css={children && Margin} />}
        {icon && checked && (
          <CheckedIcon
            icon={faCheckCircle}
            color="primary"
            css={children && Margin}
          />
        )}
        {icon && !checked && (
          <CheckedIcon
            icon={faTimesCircle}
            color="error"
            css={children && Margin}
          />
        )}
        <div>{children}</div>
      </CheckboxLabel>
    </>
  );
};

export default Checkbox;
