import React, { useState } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { FormProvider, useForm } from 'react-hook-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPaperPlane,
  faCheck,
  faTimes,
} from '@fortawesome/pro-solid-svg-icons';

import Form from '@/components/atoms/Form/Form';
import Input from '@/components/atoms/Form/Input';
import Typography from '@/components/atoms/Typography';
import Grid from '@/components/atoms/Grid';
import FormError from '@/components/atoms/Form/FormError';

import renderFormErrorMessage from '@/utils/renderFormErrorMessage';
import formValidationRules from '@/utils/formValidationRules';
import mq from '@/styles/mq';

interface FormData {
  newsletter_email: string;
}

interface Props {
  type: string;
}

const NewsletterTitle = styled(Typography)`
  max-width: 320px;
  margin-bottom: ${({ theme }) => theme.spacing(1)};
  text-align: center;
  ${mq('lg')} {
    text-align: right;
  }
`;
const InputContainer = styled(Grid)`
  width: 24.5rem;
  ${mq('sm')} {
    width: 24.5rem;
  }
  position: relative;
`;
const SubmitButton = styled.button`
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  padding: ${({ theme }) => theme.spacing(1)};
  cursor: pointer;
  background-color: #efefef;
  border: none;
  height: 102%;
`;

const NewsletterForm = ({ type }: Props): JSX.Element => {
  const [status, setStatus] = useState<string>('');
  const methods = useForm();
  const { register, reset, handleSubmit, clearErrors, errors } = methods;

  const onSubmit = async (data: FormData) => {
    try {
      await fetch(
        'https://guilmin-api.netlify.app/.netlify/functions/newsletters-subscribe',
        {
          mode: `no-cors`,
          method: `POST`,
          body: JSON.stringify({ email: data?.newsletter_email, type }),
        },
      );
      setStatus('success');
      setTimeout(() => setStatus(''), 3000);
      reset();
    } catch (e) {
      setStatus('error');
      setTimeout(() => setStatus(''), 3000);
      reset();
    }
  };

  const onInputChange = () => {
    clearErrors();
  };
  const setStatusColor = (statusType: string) => {
    switch (statusType) {
      case 'success':
        return css`
          background-color: rgba(0, 250, 0, 0.1);
        `;
      case 'error':
        return css`
          background-color: rgba(250, 0, 0, 0.1);
        `;
      default:
        return css``;
    }
  };
  const emailInputName = 'newsletter_email';
  const inputType = 'email';

  const emailValidation = formValidationRules(inputType, true);
  const emailError = renderFormErrorMessage(
    errors?.[emailInputName]?.type,
    inputType,
  );

  return (
    <FormProvider {...methods}>
      <NewsletterTitle variant="textMd" color="textSecondary">
        Inscrivez-vous à notre newsletter et restez informé sur nos nouveautés
        et promotions
      </NewsletterTitle>
      <Form
        name="newsletter_form"
        onSubmit={handleSubmit(onSubmit)}
        method="POST"
      >
        <InputContainer mt={1} container flexWrap="nowrap">
          <Input
            css={setStatusColor(status)}
            required
            onChange={onInputChange}
            type={inputType}
            name={emailInputName}
            placeholder="Votre adresse e-mail"
            ref={register(emailValidation)}
          />
          <SubmitButton type="submit">
            {status === '' && <FontAwesomeIcon icon={faPaperPlane} />}
            {status === 'success' && <FontAwesomeIcon icon={faCheck} />}
            {status === 'error' && <FontAwesomeIcon icon={faTimes} />}
          </SubmitButton>
        </InputContainer>
        {errors[emailInputName] && <FormError>{emailError}</FormError>}
      </Form>
    </FormProvider>
  );
};

export default NewsletterForm;
