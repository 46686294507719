import React from 'react';
import styled from '@emotion/styled';

export interface LabelProps {
  children: React.ReactNode;
  htmlFor: string;
}

const LabelRoot = styled.label<LabelProps>`
  display: block;
  font-size: 1.6rem;
  font-weight: 500;
  color: ${({ theme }) => theme.color.textSecondary.main};
`;

/**
 <h3>A form label to associate with an input</h3>
 **/
const Label = ({ children, htmlFor, ...rest }: LabelProps): JSX.Element => {
  return (
    <LabelRoot htmlFor={htmlFor} {...rest}>
      {children}
    </LabelRoot>
  );
};

export default Label;
