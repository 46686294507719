import React from 'react';

import MobileNav from '@/components/organisms/Navigation/MobileNav';
import DesktopNav from '@/components/organisms/Navigation/DesktopNav';

interface Props {
  invert?: boolean;
  show?: boolean;
  type?: `usine` | `magasin`;
}

// const links = [
//   { label: 'Catégories', path: '/products' },
//   {
//     label: 'Services',
//     dropdown: [
//       { label: 'Industrie', path: '/services/industrie' },
//       { label: 'Magasin', path: '/services/magasin' },
//     ],
//   },
//   { label: 'Histoire', path: '/history' },
//   { label: 'Actualités', path: '/blog' },
// ];

const IndustryLinks = [
  { label: 'Usine', path: '/usine' },
  { label: 'Services', path: '/usine/services' },
  { label: 'Environnement', path: '/usine/environnement' },
  { label: 'Fabrication', path: '/usine/fabrication' },
  { label: 'À propos', path: '/usine/a-propos' },
  // {
  //   label: 'À propos',
  //   dropdown: [
  //     { label: 'Histoire', path: '/usine/histoire' },
  //     { label: 'Présentation', path: '/usine/?' },
  //     { label: 'Philosophie', path: '/usine/?' },
  //   ],
  // },
];

const ShopLinks = [
  { label: 'Magasin', path: '/magasin' },
  { label: 'Produits', path: '/magasin/produits' },
  { label: 'Environnement', path: '/magasin/environnement' },
  // {
  //   label: 'Produits',
  //   dropdown: [
  //     {
  //       label: 'Peintures, vernis et dérivés',
  //       dropdown: [
  //         { label: 'Mur et plafond', path: '/magasin/produits/peintures/?' },
  //         { label: 'Bois', path: '/magasin/produits/peintures/?' },
  //         { label: 'Métal', path: '/magasin/produits/peintures/?' },
  //         { label: 'Sol et parquet', path: '/magasin/produits/peintures/?' },
  //         { label: 'Multi', path: '/magasin/produits/peintures/?' },
  //         { label: 'Spécial', path: '/magasin/produits/peintures/?' },
  //         { label: 'Laques', path: '/magasin/produits/peintures/?' },
  //         { label: 'Vernis', path: '/magasin/produits/peintures/?' },
  //       ],
  //     },
  //     { label: 'Moulures', path: '/magasin/produits/moulures' },
  //     { label: 'Stores', path: '/magasin/produits/stores' },
  //     { label: 'Tissus et papiers peints', path: '/magasin/produits/tissus' },
  //     { label: 'Parquets', path: '/magasin/produits/parquets' },
  //     { label: 'Accessoires', path: '/magasin/produits/accessoires' },
  //   ],
  // },
  { label: 'Services', path: '/magasin/services' },
  { label: 'À propos', path: '/magasin/a-propos' },
  // { label: 'Actualités', path: '/magasin/actualites' },
];

const ShopContactLink = {
  label: 'Contactez-nous',
  path: '/magasin/contact',
};

const IndustryContactLink = {
  label: 'Contactez-nous',
  path: '/usine/contact',
};

const Navigation = ({ invert, show, type }: Props): JSX.Element => {
  const links = type === `usine` ? IndustryLinks : ShopLinks;
  const contactLink = type === `usine` ? IndustryContactLink : ShopContactLink;
  return (
    <>
      <DesktopNav
        invert={invert}
        links={links}
        show={show}
        contact={contactLink}
      />
      <MobileNav links={links} show={show} contact={contactLink} />
    </>
  );
};

export default Navigation;
