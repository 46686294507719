import React, { useEffect, useRef, useState } from 'react';
import styled from '@emotion/styled';
import { useScrollPosition } from '@n8tb1t/use-scroll-position';
import { css, Theme } from '@emotion/react';

import Button from '@/components/atoms/Button';
import Link from '@/components/atoms/Link';
import Container from '@/components/atoms/Container';
import Typography from '@/components/atoms/Typography';
import Grid from '@/components/atoms/Grid';

import useOnClickOutside from '@/hooks/useOutsideClick';

import mq from '@/styles/mq';

import LinkDataType from '@/types/data/link';

interface NavLinkProps {
  link?: LinkDataType;
}

const SubDropdownRoot = styled('div')`
  position: relative;
`;
const SubDropdownLinkMain = styled('li')`
  color: ${({ theme }) => theme.color.primary.light} !important;
  text-align: center !important;
  /* margin-right: ${({ theme }) => theme.spacing(7.5)}; */
  position: relative;
  cursor: pointer;
  &:after {
    display: block;
    position: absolute;
    bottom: -10px;
    left: 50%;
    transform: translateX(-0%);
    content: '';
    width: 7px;
    height: 7px;
    background-color: ${({ theme }) => theme.color.primary.main};
    border-radius: 50%;
    opacity: 0;
    transition: opacity 0.3s ease;
  }
  &:hover {
    color: ${({ theme }) => theme.color.primary.main} !important;
  }
  &:hover:after {
    opacity: 1;
  }
`;
const SubDropdownSubRoot = styled(Grid)`
  position: absolute;
  bottom: 50px;
  left: 110%;
  transform: translateY(100%) scale(0.7);
  background-color: ${({ theme }) => theme.color.white.main};
  padding-top: ${({ theme }) => theme.spacing(1.5)};
  padding-bottom: ${({ theme }) => theme.spacing(1.5)};
  box-shadow: 0 7px 14px 2px ${({ theme }) => theme.color.textSecondary.dark};
  border-radius: 10px;
  transition: all 0.3s ease;

  opacity: 0;
`;
const SubDropdownLinkSub = styled(Link)`
  margin-top: ${({ theme }) => theme.spacing(1.5)};
  margin-bottom: ${({ theme }) => theme.spacing(1.5)};
  position: relative;

  &:after {
    display: block;
    position: absolute;
    bottom: -10px;
    left: 50%;
    transform: translateX(-0%);
    content: '';
    width: 7px;
    height: 7px;
    background-color: ${({ theme }) => theme.color.primary.main};
    border-radius: 50%;
    opacity: 0;
    transition: opacity 0.3s ease;
  }

  &:hover:after {
    opacity: 1;
  }
`;

const ShowDropdown = css`
  opacity: 1;
  transform: translateY(100%) scale(1);
`;

const SubDropdownLink = ({ link }: NavLinkProps): JSX.Element => {
  const [openDropdown, setOpenDropdown] = useState<boolean>(false);
  const dropdownBoxRef = useRef(null);

  const { label, dropdown } = link || {};

  useOnClickOutside(dropdownBoxRef, () => setOpenDropdown(false));

  const handleOpenDropdown = () => {
    setOpenDropdown(true);
  };

  return (
    <SubDropdownRoot ref={dropdownBoxRef}>
      {/* Clickable main link */}
      <SubDropdownLinkMain onClick={handleOpenDropdown}>
        {label}
      </SubDropdownLinkMain>
      {/* Dropdown box */}
      <SubDropdownSubRoot
        container
        flexDirection="column"
        alignItems="center"
        css={openDropdown && ShowDropdown}
      >
        {/* Sub links */}
        {dropdown?.map(
          (drop, i) =>
            drop.path && (
              <SubDropdownLinkSub key={i.toString()} to={drop.path}>
                {drop.label}
              </SubDropdownLinkSub>
            ),
        )}
      </SubDropdownSubRoot>
    </SubDropdownRoot>
  );
};

export default SubDropdownLink;
