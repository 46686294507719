import React from 'react';
import styled from '@emotion/styled';

import setColor from '@/utils/setColor';

import ColorType from '@/types/style/color';

export interface HamburgerProps {
  /**
   If opened display hamburger icon else display close icon
   */
  opened: boolean;
  /**
   The color of the spans lines creating the hamburger & close icons
   */
  color?: ColorType;
}

const HamburgerLine = styled('span')`
  display: inline-block;
  width: 18px;
  height: 2px;
  position: absolute;
  opacity: 1;
  transition: 0.25s ease-in-out;
  border-radius: 10px;
`;

const HamburgerRoot = styled('div')<HamburgerProps>`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  transition: 0.5s ease-in-out;

  ${HamburgerLine} {
    position: ${({ opened }) => (opened ? 'absolute' : 'initial')};
    top: calc(50% - 2px / 2);
    left: calc(50% - 18px / 2);
    background-color: ${({ color = 'textPrimary' }) => setColor(color)};
  }

  ${HamburgerLine}:not(:last-of-type) {
    margin-bottom: ${({ opened, theme }) => (opened ? 0 : theme.spacing(0.3))};
  }

  ${HamburgerLine}:first-of-type {
    transform: ${({ opened }) => (opened ? 'rotate(-45deg)' : 'rotate(0deg)')};
  }

  ${HamburgerLine}:nth-of-type(2) {
    opacity: ${({ opened }) => (opened ? 0 : 1)};
  }

  ${HamburgerLine}:last-of-type {
    transform: ${({ opened }) => (opened ? 'rotate(45deg)' : 'rotate(0deg)')};
  }
`;

/**
 <h3>Display a custom hamburger icon transforming into a close icon when menu is opened</h3>
 **/
const Hamburger = ({
  opened,
  color = 'textPrimary',
  ...rest
}: HamburgerProps): JSX.Element => {
  return (
    <HamburgerRoot opened={opened} color={color} {...rest}>
      <HamburgerLine />
      <HamburgerLine />
      <HamburgerLine />
    </HamburgerRoot>
  );
};

export default Hamburger;
